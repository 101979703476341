import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/day/:date',
    name: 'day',
    component: () => import('@/views/Day.vue')
  },
  {
    path: '/day/:date/:id',
    name: 'service',
    component: () => import('@/views/Service.vue')
  },
  {
    path: '/skypes',
    name: 'skypes',
    component: () => import('@/views/Skypes.vue')
  },
  {
    path: '/skypespw',
    name: 'skypespw',
    component: () => import('@/views/SkypesPW.vue')
  },
  {
    path: '/skypes/:id',
    name: 'skypes-day',
    component: () => import('@/views/SkypeBlock.vue')
  },
  {
    path: '/links',
    name: 'links',
    component: () => import('@/views/Links.vue')
  },
  {
    path: '/psaltir/:block_id',
    name: 'psaltir',
    component: () => import('@/views/Psaltir.vue')
  },
  {
    path: '/psaltir/:block_id/:kafisma_id',
    name: 'kafisma',
    component: () => import('@/views/PsaltirText.vue')
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
});

export default router
