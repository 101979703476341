import Vue from 'vue'
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

axios.defaults.baseURL = 'https://pr.energogroup.org/api';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const sPhrase = 'blago';

const store = new Vuex.Store({
  state: {
    days: [],
    currDay: {},
    service: {},
    prevPrayer: {},
    nextPrayer: {},
    position: {},
    skypes: {},
    skypeBlock: {},
    talks: [],
    secure: false,
    psaltirBlock: [],
    psaltirKafismas: [],
    kafismaPslams: [],
  },
  getters: {
    filtedLinks: (state) => (year) => {
      return state.talks.filter(item => item.created_at.slice(0, 4) == year)
    },
    getAllYears: (state) => {
      return Array.from(new Set(state.talks.map(item => item.created_at.slice(0, 4)))).sort((a, b) => b - a)
    },
  },
  mutations: {
    addError: (state, e) => state.errors.push(e.toJSON()),
    setDays: (state, list) => state.days = list,
    setCurrDay: (state, list) => state.currDay = list,
    setPrevPsaltirPrayer: (state, prayer) => state.prevPrayer = prayer,
    setNextPsaltirPrayer: (state, prayer) => state.nextPrayer = prayer,
    setPosition: (state, pos) => state.position = pos,
    setService: (state, item) => state.service = item,
    setSkypes: (state, item) => state.skypes = item,
    setSkypeBlock: (state, item) => state.skypeBlock = item,
    setTalks: (state, items) => state.talks = items,
    setSecure: (state, b) => state.secure = b,
    setPsaltirBlock: (state, list) => state.psaltirBlock = list,
    setPsaltirKafismas: (state, item) => state.psaltirKafismas = item,
    setKafismaPsalms: (state, item) => state.kafismaPslams = item,
  },
  actions: {
    getDays({commit}) {
      axios.get(`/church`).then(res => commit('setDays', res.data))
    },
    selectDay({commit}, date) {
      axios.get(`/church/${date}`).then(res => commit('setCurrDay', res.data))
    },
    selectService({commit}, params) {
      axios.get(`/church/${params.date}/${params.id}`).then(res => {
        commit(params.nameMutation ? params.nameMutation : 'setService', res.data)
      })
    },
    selectServicePosition({commit}, params) {
      axios.get(`/church/${params.date}/${params.id}`).then(res => {
        let list = res.data.services;
        let curr = list.indexOf(list.find(i => i.id === parseInt(params.id)));
        let prev = 0;
        let next = 0;
        if (curr > 0)
          prev = list[curr - 1].id;
        if (curr < (list.length - 1))
          next = list[curr + 1].id;
        commit('setPosition', {prev: prev, next: next})
      })
    },
    getSkypes({commit}) {
      axios.get('/church/skype').then(res => commit('setSkypes', res.data))
    },
    getSkypeBlock({commit}, params) {
      axios.get(`/church/skype/${params.id}`).then(res => commit('setSkypeBlock', res.data))
    },
    getTalks({commit}) {
      axios.get('/church/talks').then(res => commit('setTalks', res.data))
    },
    getPsaltirBlocks({commit}) {
      axios.get('/psaltir').then(res => commit('setPsaltirBlock', res.data))
    },
    getPsaltirKafismas({commit}, params) {
      axios.get(`/psaltir/${params.block_id}`).then(res => commit('setPsaltirKafismas', res.data))
    },
    getKafismaPslams({commit}, params) {
      axios.get(`/psaltir/${params.block_id}/${params.kafisma_id}`).then(res => commit('setKafismaPsalms', res.data))
    },
    testSecure({commit}, phrase = '') {
      let s = false;
      if (phrase) {
        localStorage.setItem('pwd', phrase);
        if (phrase === sPhrase)
          s = true;
      } else {
        if (localStorage.getItem('pwd') === sPhrase)
          s = true;
      }
      commit('setSecure', s);
    }
  },
});

export default store;
