<template>
  <div id="app">
    <div class="content">
      <div class="top">
        <router-link :to="{name:'home'}"><span class="pc-nav">На главную</span><i class="fas fa-home mobile-nav"></i></router-link>
        <span class="pc-nav" v-on:click="menuShow = !menuShow">Меню</span><i v-if="!menuShow" class="fas fa-bars mobile-nav" v-on:click="menuShow = !menuShow"></i>
        <i v-on:click="menuShow = !menuShow" v-if="menuShow" class="fas fa-times mobile-nav" style="font-size: 1.5rem"></i>
      </div>
      <div class="menu" v-show="menuShow" v-on:click="menuShow = false">
        <router-link :to="{name:'skypes'}">Онлайн конференции</router-link>
        <hr>
        <a href="http://radio.zakonbozhiy.ru:8000/live.mp3" target="_blank">Онлайн-трансляция общины арх. Михаила</a>
        <a href="http://radio.zakonbozhiy.ru:8010/pls.mp3" target="_blank">Онлайн-трансляция общины  в честь Покрова Пресвятой Богородицы</a>
        <a href="http://radio.zakonbozhiy.ru:8010/kem.mp3" target="_blank">Онлайн-трансляция общины вмц. Варвары</a>
        <hr>
        <router-link :to="{name:'links'}">Записи просветительских бесед</router-link>
        <hr>
        <router-link v-for="i in days" :to="{name:'day', params: { date: i.date }}" :key="'menu-' + i.date">
          {{ i.dateTxt }}<br>{{ i.name }}
        </router-link>
        <router-link v-for="(i, index) in psaltirBlock" :to="{name:'psaltir', params: { block_id: i.id }}" :key="'psaltir-' + index">
          {{ i.name }}
          <span v-if="i.desc"><br>{{ i.desc }}</span>
        </router-link>
      </div>
      <div class="content-main">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    name: 'App',
    data: function () {
      return {
        menuShow: false,
      }
    },
    created() {
      document.title = "Помощник чтеца";
      this.fetch()
    },
    watch: {
      $route: 'fetch'
    },
    methods: {
      fetch() {
        this.menuShow = false;
        this.$store.dispatch('getDays');
        this.$store.dispatch('getPsaltirBlocks');
      }
    },
    computed: {
      ...mapState({
        days: state => state.days,
        psaltirBlock: state => state.psaltirBlock
      })
    },
  }
</script>

<style lang="less">
  @import "assets/vars";

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100vh;
    font: 24px "Cyrillic Old", sans-serif;
    line-height: 1.2;
  }

  body {
    padding: 0;
    margin: 0;
    color: @color-d;
    min-height: 100%;
    background: #fff5d5 url("assets/img/bg.png") top center repeat;
    @media (max-width: 767px) {
        background: #fffefb;
    }
  }

  #app {
    display: block;
    max-width: 800px;
    margin: auto;
    /*grid-template: ". content .";
    grid-template-columns: auto @width-b auto;*/
    margin-bottom: 1rem;

    .content {
      grid-area: content;
      min-height: 100vh;
      position: relative;

      .top {
        height: 3rem;
        /*background: url("assets/img/top.png") center top repeat-x;*/
        background-size: auto 3rem;
        margin-bottom: 2px;
        display: flex;
        justify-content: space-around;
        line-height: 3rem;

        & > * {
          color: @color-h;
          text-decoration: none;
          cursor: pointer;
        }
        .mobile-nav {
            display:none;
        }
        @media print {
            display: none;
        }
        @media (max-width: 767px) {
            justify-content: space-between;
            font-size: 1.4rem;
            background: #fffefb;
            .pc-nav {
                display: none;
            }
            .mobile-nav {
                padding: 1rem;
                display: flex;
            }
        }
      }

      .menu {
        position: absolute;
        right: 0;
        top: 3rem;
        border: 1px solid @color-h;
        background-color: @color-l;
        padding: .5rem;

        a {
          display: block;
          margin-bottom: 1rem;
          color: @color-h;
          text-decoration: none;
        }
      }

      .content-main {
        background-color: @color-l;
        padding: 1rem;
      }
    }
  }

  @media (max-width: @width-b) {
    #app {
      display: block;
      height: 100vh;
    }
  }

</style>
